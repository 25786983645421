import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// import { getAuth } from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCVyt01pnF8v-MbNjf5PEj_kBx9n0fmSrk",
  authDomain: "dawn-servers.firebaseapp.com",
  projectId: "dawn-servers",
  storageBucket: "dawn-servers.appspot.com",
  messagingSenderId: "15123356591",
  appId: "1:15123356591:web:9bf89b06bf5fcb0dfd6d5a",
}


const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const db = getFirestore(app);

export { db };
export default app;