const pages = [
  {
    name: "home",
    link: "/",
    id: 1,
  },
  {
    name: "about",
    link: "/about",
    id: 2
  },
  {
    name: "anthum",
    link: "/anthum",
    id: 3
  },
  {
    name: "news",
    link: "/news",
    id: 4
  },
  {
    name: "contact",
    link: "/contact",
    id: 5
  },
  {
    name: "discord",
    link: "/discord",
    id: 6
  },
  {
    name: "store",
    link: "/store",
    id: 7
  },
  {
    name: "marketplace",
    link: "/marketplace",
    id: 8
  },
  
]

export default pages;